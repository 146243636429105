import i18n from '@/libs/i18n'
import Vue from 'vue'
import VueRouter from 'vue-router'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import { canNavigate } from '@/libs/acl/routeProtection'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [{
      path: '/dashboard',
      alias: '/',
      name: 'dashboard',
      component: () =>
        import ('@/views/Home.vue'),
      meta: {
        requiresAuth: true,
        action: 'manage',
        subject: 'all',
        pageTitle: 'Übersicht',
        breadcrumb: [{
          text: 'Übersicht',
          active: true,
        }, ],
      },
    },
    // *===============================================---*
    // *--------- CUSTOMER ---- ---------------------------------------*
    // *===============================================---*
    {
      path: '/customers/list',
      alias: '/customers',
      name: 'customers-list',
      component: () =>
        import ('@/views/customer/customers-list/CustomerList.vue'),
      meta: {
        requiresAuth: true,
        action: 'read',
        subject: 'all',
        pageTitle: 'Kunde',
        breadcrumb: [{
          text: 'Kunde',
          active: true,
        }, ],
      },
    },
    {
      path: '/customers/add/:mitarbeiter_id',
      name: 'customers-add',
      component: () =>
        import ('@/views/customer/customer-add/CustomerAdd.vue'),
      meta: {
        resource: 'Auth',
        action: 'read',
        subject: 'all',
        layout: 'full',
        pageTitle: 'Neuer Kunde',
        breadcrumb: [{
          text: 'Neuer Kunde',
          active: true,
        }, ],
      }
    },
    {
      path: '/customers/sign-again/:id/:mitarbeiter_id',
      name: 'customers-sign',
      component: () =>
        import ('@/views/customer/customer-add/CustomerSignAgain.vue'),
      meta: {
        resource: 'Auth',
        action: 'read',
        subject: 'all',
        layout: 'full',
        pageTitle: 'Neuer Kunde',
        breadcrumb: [{
          text: 'Neuer Kunde',
          active: true,
        }, ],
      },
    },
    {
      path: '/customers/submitted/',
      name: 'customers-success',
      component: () =>
        import ('@/views/customer/customer-add/Success.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        action: 'read',
      }
    },
    {
      path: '/customers/view/:id',
      name: 'customers-view',
      component: () =>
        import ('@/views/customer/customers-view/CustomersView.vue'),
      meta: {
        requiresAuth: true,
        action: 'read',
        subject: 'all',
        pageTitle: 'Kunde',
        breadcrumb: [{
          text: 'Kunde',
          active: true,
        }, ],
      },
    },
    {
      path: '/customers/edit/:id',
      name: 'customers-edit',
      component: () =>
        import ('@/views/customer/customers-edit/CustomersEdit.vue'),
      meta: {
        requiresAuth: true,
        action: 'read',
        subject: 'all',
        pageTitle: `${i18n.t('Edit')} Kunde`,
        breadcrumb: [{
          text: `${i18n.t('Edit')} Kunde`,
          active: true,
        }, ],
      },
    },
    // *===============================================---*
    // *--------- Treatment ---- ---------------------------------------*
    // *===============================================---*
    {
      path: '/treatments/list',
      alias: '/treatments',
      name: 'treatments-list',
      component: () =>
        import ('@/views/treatment/treatments-list/TreatmentList.vue'),
      meta: {
        requiresAuth: true,
        action: 'read',
        subject: 'all',
        pageTitle: 'Behandlung',
        breadcrumb: [{
          text: 'Behandlung',
          active: true,
        }, ],
      },
    },
    // *===============================================---*
    // *--------- Employee ---- ---------------------------------------*
    // *===============================================---*
    {
      path: '/employees/list',
      alias: '/employees',
      name: 'employees-list',
      component: () =>
        import ('@/views/employee/employees-list/EmployeeList.vue'),
      meta: {
        requiresAuth: true,
        action: 'manage',
        subject: 'all',
        pageTitle: 'Mitarbeiter',
        breadcrumb: [{
          text: 'Mitarbeiter',
          active: true,
        }, ],
      },
    },
    {
      path: '/employees/edit/:id',
      name: 'employees-edit',
      component: () =>
        import ('@/views/employee/employees-edit/EmployeesEdit.vue'),
      meta: {
        requiresAuth: true,
        action: 'manage',
        subject: 'all',
        pageTitle: `${i18n.t('Edit')} Mitarbeiter`,
        breadcrumb: [{
          text: `${i18n.t('Edit')} Mitarbeiter`,
          active: true,
        }, ],
      },
    },
    {
      path: '/contract',
      name: 'contract',
      component: () =>
        import ('@/views/Contract.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        action: 'read',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () =>
        import ('@/views/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () =>
        import ('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        action: 'read',
      },
    },
    {
      path: '/not-authorized',
      name: 'not-authorized',
      component: () =>
        import ('@/views/error/NotAuthorized.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        action: 'read',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'contract' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router