import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
    "services": [
      {
        "ID": "ASC-1",
        "Category": "Haarentfernung - Frauen",
        "ServiceName": "Oberlippe",
        "Time": 10,
        "Price": 30
      },
      {
        "ID": "ASC-2",
        "Category": "Haarentfernung - Frauen",
        "ServiceName": "Kinn",
        "Time": 10,
        "Price": 30
      },
      {
        "ID": "ASC-3",
        "Category": "Haarentfernung - Frauen",
        "ServiceName": "Gesicht komplett (Oberlippe + Kinn)",
        "Time": 15,
        "Price": 50
      },
      {
        "ID": "ASC-4",
        "Category": "Haarentfernung - Frauen",
        "ServiceName": "Achsel",
        "Time": 15,
        "Price": 50
      },
      {
        "ID": "ASC-5",
        "Category": "Haarentfernung - Frauen",
        "ServiceName": "Décollté",
        "Time": 15,
        "Price": 45
      },
      {
        "ID": "ASC-6",
        "Category": "Haarentfernung - Frauen",
        "ServiceName": "Unterarme",
        "Time": 15,
        "Price": 40
      },
      {
        "ID": "ASC-7",
        "Category": "Haarentfernung - Frauen",
        "ServiceName": "Rücken",
        "Time": 15,
        "Price": 45
      },
      {
        "ID": "ASC-8",
        "Category": "Haarentfernung - Frauen",
        "ServiceName": "Bauch",
        "Time": 15,
        "Price": 50
      },
      {
        "ID": "ASC-9",
        "Category": "Haarentfernung - Frauen",
        "ServiceName": "Bikini",
        "Time": 20,
        "Price": 50
      },
      {
        "ID": "ASC-10",
        "Category": "Haarentfernung - Frauen",
        "ServiceName": "Bikini und Intim",
        "Time": 25,
        "Price": 80
      },
      {
        "ID": "ASC-11",
        "Category": "Haarentfernung - Frauen",
        "ServiceName": "Oberschenkel",
        "Time": 25,
        "Price": 60
      },
      {
        "ID": "ASC-12",
        "Category": "Haarentfernung - Frauen",
        "ServiceName": "Unterschenkel",
        "Time": 25,
        "Price": 60
      },
      {
        "ID": "ASC-13",
        "Category": "Haarentfernung - Frauen",
        "ServiceName": "Pobacke",
        "Time": 15,
        "Price": 40
      },
      {
        "ID": "ASC-14",
        "Category": "Haarentfernung - Frauen",
        "ServiceName": "Pobacke inkl. Pofalte",
        "Time": 20,
        "Price": 50
      },
      {
        "ID": "ASC-15",
        "Category": "Haarentfernung - Frauen",
        "ServiceName": "Ganzkörper, exkl. Bauch/Rücken oder Gesicht/Décollté",
        "Time": 90,
        "Price": 210
      },
      {
        "ID": "ASC-16",
        "Category": "Haarentfernung - Männer",
        "ServiceName": "Oberlippe",
        "Time": 15,
        "Price": 35
      },
      {
        "ID": "ASC-17",
        "Category": "Haarentfernung - Männer",
        "ServiceName": "Kinn",
        "Time": 15,
        "Price": 35
      },
      {
        "ID": "ASC-18",
        "Category": "Haarentfernung - Männer",
        "ServiceName": "Gesicht komplett (Oberlippe + Kinn)",
        "Time": 20,
        "Price": 55
      },
      {
        "ID": "ASC-19",
        "Category": "Haarentfernung - Männer",
        "ServiceName": "Hals",
        "Time": 20,
        "Price": 50
      },
      {
        "ID": "ASC-20",
        "Category": "Haarentfernung - Männer",
        "ServiceName": "Achsel",
        "Time": 20,
        "Price": 50
      },
      {
        "ID": "ASC-21",
        "Category": "Haarentfernung - Männer",
        "ServiceName": "Brust",
        "Time": 30,
        "Price": 60
      },
      {
        "ID": "ASC-22",
        "Category": "Haarentfernung - Männer",
        "ServiceName": "Unterarme",
        "Time": 25,
        "Price": 50
      },
      {
        "ID": "ASC-23",
        "Category": "Haarentfernung - Männer",
        "ServiceName": "Rücken",
        "Time": 60,
        "Price": 120
      },
      {
        "ID": "ASC-24",
        "Category": "Haarentfernung - Männer",
        "ServiceName": "Bauch",
        "Time": 30,
        "Price": 60
      },
      {
        "ID": "ASC-25",
        "Category": "Haarentfernung - Männer",
        "ServiceName": "Oberschenkel",
        "Time": 50,
        "Price": 80
      },
      {
        "ID": "ASC-26",
        "Category": "Haarentfernung - Männer",
        "ServiceName": "Unterschenkel",
        "Time": 40,
        "Price": 70
      },
      {
        "ID": "ASC-27",
        "Category": "Haarentfernung - Männer",
        "ServiceName": "Ganzkörper, exkl. Intimbereich",
        "Time": 90,
        "Price": 190
      },
      {
        "ID": "ASC-28",
        "Category": "Tattooentfernung - Männer",
        "ServiceName": "Grösse S, bis 50cm2",
        "Time": 10,
        "Price": 70
      },
      {
        "ID": "ASC-29",
        "Category": "Tattooentfernung - Männer",
        "ServiceName": "Grösse M, bis 100cm2",
        "Time": 15,
        "Price": 130
      },
      {
        "ID": "ASC-30",
        "Category": "Tattooentfernung - Männer",
        "ServiceName": "Grösse L, bis 150cm2",
        "Time": 30,
        "Price": 190
      },
      {
        "ID": "ASC-31",
        "Category": "Carbon Peeling - Frau",
        "ServiceName": "Gesicht",
        "Time": 40,
        "Price": 120
      },
      {
        "ID": "ASC-32",
        "Category": "Carbon Peeling - Frau",
        "ServiceName": "Gesicht und Hals",
        "Time": 50,
        "Price": 150
      },
      {
        "ID": "ASC-33",
        "Category": "Carbon Peeling - Frau",
        "ServiceName": "Gesicht, Hals und Décollté",
        "Time": 60,
        "Price": 180
      },
      {
        "ID": "ASC-34",
        "Category": "Wimpernverlängerung - 1:1 Einzeltechnick",
        "ServiceName": "Wimpernverlängerung 1. set",
        "Time": 120,
        "Price": 160
      },
      {
        "ID": "ASC-35",
        "Category": "Wimpernverlängerung - 1:1 Einzeltechnick",
        "ServiceName": "Wimpernverlängerung +1 Woche",
        "Time": 90,
        "Price": 45
      },
      {
        "ID": "ASC-36",
        "Category": "Wimpernverlängerung - 1:1 Einzeltechnick",
        "ServiceName": "Wimpernverlängerung +2 Woche",
        "Time": 90,
        "Price": 65
      },
      {
        "ID": "ASC-37",
        "Category": "Wimpernverlängerung - 1:1 Einzeltechnick",
        "ServiceName": "Wimpernverlängerung +3 Woche",
        "Time": 90,
        "Price": 85
      },
      {
        "ID": "ASC-38",
        "Category": "Wimpernverlängerung - 1:1 Einzeltechnick",
        "ServiceName": "Wimpernverlängerung +4 Woche",
        "Time": 90,
        "Price": 105
      },
      {
        "ID": "ASC-39",
        "Category": "Wimpernverlängerung - 3D-10D Volumentechnick",
        "ServiceName": "Wimpernverlängerung 1. set",
        "Time": 120,
        "Price": 170
      },
      {
        "ID": "ASC-40",
        "Category": "Wimpernverlängerung - 3D-10D Volumentechnick",
        "ServiceName": "Wimpernverlängerung +1 Woche",
        "Time": 45,
        "Price": 55
      },
      {
        "ID": "ASC-41",
        "Category": "Wimpernverlängerung - 3D-10D Volumentechnick",
        "ServiceName": "Wimpernverlängerung +2 Woche",
        "Time": 60,
        "Price": 75
      },
      {
        "ID": "ASC-42",
        "Category": "Wimpernverlängerung - 3D-10D Volumentechnick",
        "ServiceName": "Wimpernverlängerung +3 Woche",
        "Time": 75,
        "Price": 95
      },
      {
        "ID": "ASC-43",
        "Category": "Wimpernverlängerung - 3D-10D Volumentechnick",
        "ServiceName": "Wimpernverlängerung +4 Woche",
        "Time": 90,
        "Price": 115
      },
      {
        "ID": "ASC-44",
        "Category": "Haarentfernung - Frauen",
        "ServiceName": "Ganzkörper Deluxe (Beine, Intim komplett, Achseln, Arme, Gesicht und Rücken/Bauch)",
        "Time": 135,
        "Price": 250
      },
      {
        "ID": "ASC-45",
        "Category": "Haarentfernung - Frauen",
        "ServiceName": "Ganzkörper Special (Beine, Intim komplett, Achseln, Arme und Rücken/Bauch)",
        "Time": 120,
        "Price": 230
      },
      {
        "ID": "ASC-46",
        "Category": "Haarentfernung - Frauen",
        "ServiceName": "Ganzkörper Premium (Beine, Intim komplett, Achseln, Arme und Gesicht)",
        "Time": 90,
        "Price": 210
      },
      {
        "ID": "ASC-47",
        "Category": "Haarentfernung - Frauen",
        "ServiceName": "Arme komplett",
        "Time": 30,
        "Price": 80
      },
      {
        "ID": "ASC-48",
        "Category": "Haarentfernung - Frauen",
        "ServiceName": "Beine komplett",
        "Time": 70,
        "Price": 100
      },
      {
        "ID": "ASC-49",
        "Category": "Haarentfernung - Frauen",
        "ServiceName": "Ganzkörper Standart (Beine, Intim komplett, Achseln und Arme)",
        "Time": 90,
        "Price": 190
      },
      {
        "ID": "ASC-50",
        "Category": "Haarentfernung - Männer",
        "ServiceName": "Ganzkörper Deluxe (Arme, Achseln, Rücken/Bauch, Gesicht und Beine)",
        "Time": 140,
        "Price": 270
      },
      {
        "ID": "ASC-51",
        "Category": "Haarentfernung - Männer",
        "ServiceName": "Ganzkörper Special (Arme, Achseln, Rücken/Bauch und Beine)",
        "Time": 120,
        "Price": 250
      },
      {
        "ID": "ASC-52",
        "Category": "Haarentfernung - Männer",
        "ServiceName": "Ganzkörper Premium (Arme, Achseln, Rücken/Bauch und Gesicht)",
        "Time": 75,
        "Price": 210
      },
      {
        "ID": "ASC-53",
        "Category": "Haarentfernung - Männer",
        "ServiceName": "Ganzkörper Standart (Arme, Achseln, Rücken/Bauch)",
        "Time": 60,
        "Price": 190
      },
      {
        "ID": "ASC-54",
        "Category": "Haarentfernung - Männer",
        "ServiceName": "Arme komplett",
        "Time": 25,
        "Price": 70
      },
      {
        "ID": "ASC-55",
        "Category": "Haarentfernung - Männer",
        "ServiceName": "Beine komplett",
        "Time": 80,
        "Price": 140
      },
      {
        "ID": "ASC-56",
        "Category": "Haarentfernung - Männer",
        "ServiceName": "Brust, Bauch und Rücken",
        "Time": 45,
        "Price": 140
      },
      {
        "ID": "ASC-57",
        "Category": "Haarentfernung - Frauen",
        "ServiceName": "Ganzkörper - Simple",
        "Time": 110,
        "Price": 190
      },
      {
        "ID": "ASC-58",
        "Category": "Haarentfernung - Frauen",
        "ServiceName": "Ganzkörper - Extra",
        "Time": 130,
        "Price": 220
      },
      {
        "ID": "ASC-59",
        "Category": "Haarentfernung - Männer",
        "ServiceName": "Oberarme",
        "Time": 30,
        "Price": 70
      },
      {
        "ID": "ASC-60",
        "Category": "Haarentfernung - Männer",
        "ServiceName": "Ganzkörper - Simple",
        "Time": 150,
        "Price": 350
      },
      {
        "ID": "ASC-61",
        "Category": "Haarentfernung - Männer",
        "ServiceName": "Schultern",
        "Time": 20,
        "Price": 50
      },
      {
        "ID": "ASC-62",
        "Category": "Haarentfernung - Männer",
        "ServiceName": "Schultern, Hals und Nacken",
        "Time": 55,
        "Price": 90
      },
      {
        "ID": "ASC-63",
        "Category": "Tattooentfernung - Frau",
        "ServiceName": "Grösse S, bis 5x5cm",
        "Time": 10,
        "Price": 80
      },
      {
        "ID": "ASC-64",
        "Category": "Tattooentfernung - Frau",
        "ServiceName": "Grösse M, bis 10x10cm",
        "Time": 15,
        "Price": 130
      },
      {
        "ID": "ASC-65",
        "Category": "Tattooentfernung - Frau",
        "ServiceName": "Grösse L, bis 15x15cm",
        "Time": 30,
        "Price": 190
      },
      {
        "ID": "ASC-66",
        "Category": "Zahnbleaching",
        "ServiceName": "30 Minuten",
        "Time": 45,
        "Price": 75
      },
      {
        "ID": "ASC-67",
        "Category": "Zahnbleaching",
        "ServiceName": "60 Minuten",
        "Time": 75,
        "Price": 115
      },
      {
        "ID": "ASC-68",
        "Category": "Zahnbleaching",
        "ServiceName": "3-er Abonnement 30 Minuten",
        "Time": 45,
        "Price": 200
      },
      {
        "ID": "ASC-69",
        "Category": "Zahnbleaching",
        "ServiceName": "3-er Abonnement 60 Minuten",
        "Time": 75,
        "Price": 310
      },
      {
        "ID": "ASC-70",
        "Category": "Aqua Facial",
        "ServiceName": "Simple - Gesicht",
        "Time": 45,
        "Price": 120
      },
      {
        "ID": "ASC-71",
        "Category": "Aqua Facial",
        "ServiceName": "Simple - Gesicht, Hals",
        "Time": 50,
        "Price": 130
      },
      {
        "ID": "ASC-72",
        "Category": "Aqua Facial",
        "ServiceName": "Simple - Gesicht, Hals, Décollté",
        "Time": 60,
        "Price": 150
      },
      {
        "ID": "ASC-73",
        "Category": "Aqua Facial",
        "ServiceName": "Extra - Gesicht",
        "Time": 60,
        "Price": 160
      },
      {
        "ID": "ASC-74",
        "Category": "Aqua Facial",
        "ServiceName": "Extra - Gesicht, Hals",
        "Time": 80,
        "Price": 180
      },
      {
        "ID": "ASC-75",
        "Category": "Aqua Facial",
        "ServiceName": "Extra - Gesicht, Hals, Décollté",
        "Time": 90,
        "Price": 200
      },
      {
        "ID": "ASC-76",
        "Category": "Aqua Facial",
        "ServiceName": "Deluxe - Gesicht",
        "Time": 60,
        "Price": 180
      },
      {
        "ID": "ASC-77",
        "Category": "Aqua Facial",
        "ServiceName": "Deluxe - Gesicht, Hals",
        "Time": 80,
        "Price": 200
      },
      {
        "ID": "ASC-78",
        "Category": "Aqua Facial",
        "ServiceName": "Deluxe - Gesicht, Hals, Décollté",
        "Time": 90,
        "Price": 220
      }
    ],
    "categories": [{
        "Name": "Haarentfernung - Frauen",
        frequenz: true,
        energie: true,
        energiedichte: true,
        hauttyp: true,
        koerperregion: true,
        durchmesser: false
      },
      {
        "Name": "Haarentfernung - Männer",
        frequenz: true,
        energie: true,
        energiedichte: true,
        hauttyp: true,
        koerperregion: true,
        durchmesser: false
      },
      {
        "Name": "Tattooentfernung - Männer",
        frequenz: true,
        energie: true,
        energiedichte: true,
        hauttyp: true,
        koerperregion: true,
        durchmesser: true
      },
      {
        "Name": "Carbon Peeling - Frau",
        frequenz: true,
        energie: true,
        energiedichte: true,
        hauttyp: true,
        koerperregion: false,
        durchmesser: true
      },
      {
        "Name": "Wimpernverlängerung - 1:1 Einzeltechnick",
        frequenz: true,
        energie: true,
        energiedichte: true,
        hauttyp: true,
        koerperregion: false,
        durchmesser: true
      },
      {
        "Name": "Wimpernverlängerung - 3D-10D Volumentechnick",
        frequenz: true,
        energie: true,
        energiedichte: true,
        hauttyp: true,
        koerperregion: false,
        durchmesser: true
      },
      {
        "Name": "Aqua Facial",
        frequenz: true,
        energie: true,
        energiedichte: true,
        hauttyp: true,
        koerperregion: true,
        durchmesser: true
      },
      {
        "Name": "Zahnbleaching",
        frequenz: true,
        energie: true,
        energiedichte: true,
        hauttyp: true,
        koerperregion: true,
        durchmesser: true
      },
      {
        "Name": "Tattooentfernung - Frau",
        frequenz: true,
        energie: true,
        energiedichte: true,
        hauttyp: true,
        koerperregion: true,
        durchmesser: true
      }
    ]
  }
  /* eslint-enable */

// ------------------------------------------------
// GET: Return Customers
// ------------------------------------------------
mock.onGet('/apps/service/services').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = '',
      category = '',
      perPage = 1000,
      page = 1,
      sortBy = 'ServiceName',
      sortDesc = false,
  } = config.params
    /* eslint-enable */

  const categoryLowered = category.toLowerCase()
  const filteredData = data.services
    .filter(
      service =>
      (categoryLowered == '' || service.Category.toLowerCase() === categoryLowered))
    /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      services: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})

// ------------------------------------------------
// GET: Return Customers
// ------------------------------------------------
mock.onGet('/apps/service/categories').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = '',
      perPage = 1000,
      page = 1,
      sortBy = 'Name',
      sortDesc = false,
  } = config.params
    /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.categories.filter(
      cat =>
      (cat.Name.toLowerCase().includes(queryLowered)))
    /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      categories: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})


// ------------------------------------------------
// GET: Return Single Employee
// ------------------------------------------------
mock.onGet(/\/apps\/service\/services\/\d+/).reply(config => {
  // Get event id from URL
  let employeeId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  employeeId = Number(employeeId)

  const employeeIndex = data.employees.findIndex(e => e.id === employeeId)
  const employee = data.employees[employeeIndex]

  if (employee) return [200, employee]
  return [404]
})